import { throwError } from "@dancrumb/fpish";
import { either } from "@sutro/studio2-quarantine/sutro-api/Api";
import { SutroApi } from "@sutro/studio2-quarantine/sutro-api/index";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StudioEntitlement } from "sutro-common/studio-entitlements";

import { useProfile } from "~/lib/use-profile";

import { retrieveAuthData } from "~/lib/studio-local-storage";

type EntitlementsContextValue = {
  entitlements: StudioEntitlement[];
  refreshEntitlements: () => void;
};

const EntitlementsContext = createContext<EntitlementsContextValue>({
  entitlements: [],
  refreshEntitlements: () => {},
});

/**
 * This Provider provides a list of the current user's entitlements
 */
export const EntitlementsProvider = ({ children }: PropsWithChildren) => {
  const { currentUser } = useProfile();

  /**
   * null means that the entitlements are still loading... we will always return an array
   */
  const [entitlements, setEntitlements] = useState<StudioEntitlement[] | null>(
    null
  );

  /**
   * Load the entitlements for the current user; if there is no current user, do nothing
   */
  const loadEntitlements = useCallback(() => {
    if (!currentUser) {
      return;
    }
    const api = SutroApi.getApi().authenticate(retrieveAuthData);
    void api
      .get<StudioEntitlement[]>("/users/current/entitlements")
      .then(either<StudioEntitlement[]>(throwError).or(setEntitlements));
  }, [currentUser]);

  useEffect(() => {
    if (entitlements === null && currentUser !== null) {
      loadEntitlements();
    }
  }, [currentUser, entitlements, loadEntitlements]);

  return (
    <EntitlementsContext.Provider
      value={{
        entitlements: entitlements ?? [],
        refreshEntitlements: loadEntitlements,
      }}
    >
      {children}
    </EntitlementsContext.Provider>
  );
};

export const useEntitlements = (): EntitlementsContextValue => {
  const { entitlements, refreshEntitlements } = useContext(EntitlementsContext);

  return useMemo(
    () => ({
      entitlements,
      refreshEntitlements,
    }),
    [entitlements, refreshEntitlements]
  );
};
